import Typography from 'typography'
import Wordpress2016 from 'typography-theme-wordpress-2016'

Wordpress2016.overrideThemeStyles = () => {
	return {
		'a.gatsby-resp-image-link': {
			boxShadow: `none`,
		},
		a: {
			boxShadow: `none`,
		},
		blockquote: {
			marginLeft: `-1rem`,
		},
		'ul, ol': {
			marginLeft: `1.75rem`,
		},
	}
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
	typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
